import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Response } from '@app/core/interfaces/responses/response.interface';
import {
  UserData,
} from '@app/core/interfaces/userdata.interface';
import { typeAction } from '@app/shared/utils/enum';
import { environment } from '@src/environments/environment';
import { Observable, Subject, from } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';
import { switchMap } from 'rxjs/operators';


@Injectable()
export class UserService {
  private apiUrl = environment.urlMsAuth;
  // private apiRolesUrl = environment.urlMsUserRoles;
  // private apiJedsetterStatusUrl = environment.urlMsJedsetterStatus;
  // private apiCountriesUrl = environment.urlMsCountries;
  // private apiCurrencyUrl = environment.urlMsCurrency;
  private subjectUsers = new Subject<UserData>();
  public action: typeAction | undefined;

  constructor(private http: HttpClient, private keycloakService: KeycloakService) {}

  getUserList(params?: HttpParams): Observable<Response<UserData[]>> {
    return from(this.keycloakService.getToken()).pipe(
      switchMap((token) => {
        const headers = new HttpHeaders({
          Authorization: `Bearer ${token}`,
        });
        return this.http.get<Response<UserData[]>>(`${this.apiUrl}/admin/realms/jedsy/users`, { headers });
      })
    );
  }

  

  notifyDataSubject(user?: UserData, action?: typeAction): void {
    this.action = action;
    this.subjectUsers.next(user);
  }

  listenDataSubjectUser(): Observable<UserData> {
    return this.subjectUsers.asObservable();
  }
}
