import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { InlineSVGModule } from 'ng-inline-svg';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthModule } from './auth/auth.module';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { DateInputConverter } from './shared/utils/date-input-converter.directive';
import { KeycloakInitService } from './services/keycloak.service';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';

export function initializeKeycloak(keycloakInitService: KeycloakInitService) {
  return () => keycloakInitService.init();
}

@NgModule({
  declarations: [AppComponent, DateInputConverter],
  imports: [
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    InlineSVGModule.forRoot(),
    BrowserAnimationsModule,
    AuthModule,
    KeycloakAngularModule
  ],
  providers: [
    KeycloakService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakInitService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
