const rootElement: HTMLElement | null = document.getElementById('root');
function attributeFunction(input: string): string | null {
  return rootElement?.getAttribute(`data-${input}`) ?? null;
}

const BASE = attributeFunction('api-base-domain') ?? 'uphi.cc';

export const environment = {
  production: true,
  // Fleet (GLIDER)
  urlMsFleet: `https://glider.${BASE}/api`,
  // Rides
  urlMsRides: `https://ride.${BASE}`,
  urlMsAuth: `https://auth.${BASE}`,
  urlIpConnector: `https://ips.${BASE}`,
  urlNewbornGliderWard: `https://newborn.${BASE}`,
};
