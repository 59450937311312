import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Ride } from '@app/core/interfaces/ride.interface';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-dialog-ride-information',
  templateUrl: './dialog-ride-information.component.html',
  styleUrls: ['./dialog-ride-information.component.css'],
})
export class DialogRideInfoComponent implements OnInit, OnDestroy {
  form!: FormGroup;
  subject = new Subject<any>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Ride,
    public dialogRef: MatDialogRef<DialogRideInfoComponent>,
    public datepipe: DatePipe
  ) {
    this.form = new FormGroup({
      id: new FormControl(data.id),
      operator: new FormControl(
        data.operator_id
      ),
      location_departure: new FormControl(data.from_location),
      location_arrival: new FormControl(data.to_location),
      time_departure: new FormControl(
        this.datepipe.transform(data.departure_time, 'M/d/yy, h:mm a')
      ),
      time_arrival: new FormControl(
        this.datepipe.transform(data.arrival_time, 'M/d/yy, h:mm a')
      ),
      status: new FormControl(data.ride_status_id),
      glider_id: new FormControl(data.glider_name),
    });
  }

  ngOnInit(): void {}

  get formDialog(): any {
    return this.form.controls;
  }

  ngOnDestroy(): void {
    this.subject.complete();
    this.subject.unsubscribe();
  }
}
