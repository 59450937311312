<mat-dialog-content>
  <h2 mat-dialog-title>
    Glider details
    <span mat-button mat-dialog-close class="float-right">
      <mat-icon class="actions-only-icons">
        <span class="material-icons icon-green">
          close
        </span>
      </mat-icon>
    </span>
  </h2>
  <form [formGroup]="form">
    <div class="row mt-3">
      <div class="col-6">
        <label>
          Ride ID
          <input formControlName="id" class="form-control mt-2 input-style-form" type="text" [attr.disabled]="true">
        </label>
      </div>
      <div class="col-6">
        <label>
          Name
          <input formControlName="name" class="form-control mt-2 input-style-form" type="text" [attr.disabled]="true">
        </label>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-6">
        <label>
          IP
          <input formControlName="ip" class="form-control mt-2 input-style-form" type="text" [attr.disabled]="true">
        </label>
      </div>
      <div class="col-6">
        <label>
          VPN
          <input formControlName="vpn" class="form-control mt-2 input-style-form" type="text" [attr.disabled]="true">
        </label>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-6">
        <label>
          Status
          <input formControlName="glider_status" class="form-control mt-2 input-style-form" type="text" [attr.disabled]="true">
        </label>
      </div>
      <div class="col-6">
        <label>
          Battery
          <input formControlName="battery" class="form-control mt-2 input-style-form" type="text" [attr.disabled]="true">
        </label>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-6">
        <label>
          Ready
          <input formControlName="ready_eta" class="form-control mt-2 input-style-form" type="text" [attr.disabled]="true">
        </label>
      </div>
      <div class="col-6">
        <label>
          Landing
          <input formControlName="landing_eta" class="form-control mt-2 input-style-form" type="text" [attr.disabled]="true">
        </label>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-12">
        <label class="w-100">
          GPS
          <input formControlName="gps" class="form-control mt-2 input-style-form" type="text" [attr.disabled]="true">
        </label>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-4">
        <label>
          GPS Time
          <input formControlName="gps_time" class="form-control mt-2 input-style-form" type="text" [attr.disabled]="true">
        </label>
      </div>
    </div>
    
    <br>
  </form>
</mat-dialog-content>