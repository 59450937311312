import { Component, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'jedsy';

  constructor(private keycloakService: KeycloakService) {}

  ngOnInit() {
    this.keycloakService.keycloakEvents$.subscribe({
      next: (event) => {
        console.log('Keycloak event', event);
      },
      error: (error) => console.error('Keycloak event error', error),
    });
  }
}
