<mat-dialog-content>
  <h2 mat-dialog-title>
    Location details
    <span mat-button mat-dialog-close class="float-right">
      <mat-icon class="actions-only-icons">
        <span class="material-icons icon-green">
          close
        </span>
      </mat-icon>
    </span>
  </h2>
  <form [formGroup]="form">
    <div class="row mt-3">
      <div class="col-4">
        <label>
          Location ID
          <input formControlName="id" class="form-control mt-2 input-style-form" type="text" [attr.disabled]="true">
        </label>
      </div>
      <div class="col-4">
        <label>
          Name
          <input formControlName="name" class="form-control mt-2 input-style-form" type="text" [attr.disabled]="true">
        </label>
      </div>
      <div class="col-4">
        <label>
          Category
          <input formControlName="category" class="form-control mt-2 input-style-form" type="text" [attr.disabled]="true">
        </label>
      </div>
    </div>

    <div class="row mt-3 mr-3">
      <label>
        GPS coordinates
        <input formControlName="gps" class="form-control mt-2 input-style-form" type="text" [attr.disabled]="true">
      </label>
    </div>

    <div class="row mt-3">
      <div class="col-4">
        <label>
          GPS heading
          <input formControlName="heading" class="form-control mt-2 input-style-form" type="text" [attr.disabled]="true">
        </label>
      </div>
      <div class="col-4">
        <label>
          Owner
          <input formControlName="owner" class="form-control mt-2 input-style-form" type="text" [attr.disabled]="true">
        </label>
      </div>
      <div class="col-4">
        <label>
          Status
          <input formControlName="status" class="form-control mt-2 input-style-form" type="text" [attr.disabled]="true">
        </label>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-4">
        <label>
          Ready eta
          <input formControlName="readyEta" class="form-control mt-2 input-style-form" type="text" [attr.disabled]="true">
        </label>
      </div>
      <div class="col-4">
        <label>
          Node
          <input formControlName="node" class="form-control mt-2 input-style-form" type="text" [attr.disabled]="true">
        </label>
      </div>
      <div class="col-4">
        <label>
          Tag
          <input formControlName="tag" class="form-control mt-2 input-style-form" type="text" [attr.disabled]="true">
        </label>
      </div>
    </div>

    <div class="row mt-3">
      <label>Video</label>
      <video src="{{ data.videoUrl }}" type="video/mp4" controls width="100%" height="500px"></video>
    </div>
    <br>
  </form>
</mat-dialog-content>