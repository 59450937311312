<mat-dialog-content>
  <h2 mat-dialog-title>
    Ride details {{ data.id }}
    <span mat-button mat-dialog-close class="float-right">
      <mat-icon class="actions-only-icons">
        <span class="material-icons icon-green">
          close
        </span>
      </mat-icon>
    </span>
  </h2>
  <form [formGroup]="form">
    <div class="row mt-3">
      <div class="col-4">
        <label>
          Ride ID
          <input formControlName="id" class="form-control mt-2 input-style-form" type="text" [attr.disabled]="true">
        </label>
      </div>
      <div class="col-4">
        <label>
          Operator
          <input formControlName="operator" class="form-control mt-2 input-style-form" type="text" [attr.disabled]="true">
        </label>
      </div>
      <div class="col-4">
        <label>
          Category
          <input formControlName="category" class="form-control mt-2 input-style-form" type="text" [attr.disabled]="true">
        </label>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-6">
        <label>
          Location departure
          <input formControlName="location_departure" class="form-control mt-2 input-style-form" type="text" [attr.disabled]="true">
        </label>
      </div>
      <div class="col-6">
        <label>
          Location arrival
          <input formControlName="location_arrival" class="form-control mt-2 input-style-form" type="text" [attr.disabled]="true">
        </label>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-6">
        <label>
          Time departure
          <input formControlName="time_departure" class="form-control mt-2 input-style-form" type="text" [attr.disabled]="true">
        </label>
      </div>
      <div class="col-6">
        <label>
          Time arrival
          <input formControlName="time_arrival" class="form-control mt-2 input-style-form" type="text" [attr.disabled]="true">
        </label>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-4">
        <label>
          Status
          <input formControlName="status" class="form-control mt-2 input-style-form" type="text" [attr.disabled]="true">
        </label>
      </div>
      <div class="col-4">
        <label>
          Glider
          <input formControlName="glider_id" class="form-control mt-2 input-style-form" type="text" [attr.disabled]="true">
        </label>
      </div>
      <div class="col-4">
        <label>
          GSC
          <input formControlName="gsc_id" class="form-control mt-2 input-style-form" type="text" [attr.disabled]="true">
        </label>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-4">
        <label>
          Scheduled
          <input formControlName="scheduled" class="form-control mt-2 input-style-form" type="text" [attr.disabled]="true">
        </label>
      </div>
    </div>
    
    <br>
  </form>
</mat-dialog-content>