import { NgModule } from '@angular/core';
import { MaterialModule } from '@app/material/material.module';
import { CommonModule, DatePipe } from '@angular/common';
import { AgmCoreModule } from '@agm/core';
import { NgMagnizoomModule } from 'ng-magnizoom';

import { DialogComponent } from './components/dialog/dialog.component';
import { ReactiveFormsModule } from '@angular/forms';
import { DialogLocationInfoComponent } from './components/dialog-location-information/dialog-location-information.component';
import { DialogRideInfoComponent } from './components/dialog-ride-information/dialog-ride-information.component';
import { UserService } from '@app/services/user/user.service';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { DialogGliderInfoComponent } from './components/dialog-glider-information/dialog-glider-information.component';

@NgModule({
  declarations: [
    DialogComponent,
    DialogLocationInfoComponent,
    DialogRideInfoComponent,
    DialogGliderInfoComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCaGjRcLMon_xQ0I-yxd7xkkyHMSbUNnEM',
    }),
    NgMagnizoomModule,
    ReactiveFormsModule,
    NgxIntlTelInputModule,
  ],
  exports: [
    DialogLocationInfoComponent,
    DialogRideInfoComponent,
    DialogGliderInfoComponent,
  ],
  providers: [
    UserService,
    DatePipe,
  ],
})
export class SharedModule {}
