import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(private _snackBar: MatSnackBar) {}

  openSnackBar(
    text: string,
    btn: string,
    duration?: number,
    horizontalPosition: string = this.horizontalPosition,
    verticalPosition: string = this.horizontalPosition
  ) {
    console.log(text);
    this._snackBar.open(text, btn, {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: !duration ? 3000 : duration,
    });
  }
}
