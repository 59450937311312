// src/app/services/keycloak.service.ts

import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import keycloakConfig from '../../environments/keycloak.config';
import Keycloak from 'keycloak-js';

@Injectable({
  providedIn: 'root',
})
export class KeycloakInitService {
    private keycloakService: KeycloakService;
  constructor(private keycloak: KeycloakService) { this.keycloakService = keycloak; }

  init(): Promise<boolean> {

    // const keycloakInstance = new Keycloak(keycloakConfig);
  
      return this.keycloakService.init({
        config: keycloakConfig,
        initOptions: {
          onLoad: 'login-required',
        },
        enableBearerInterceptor: true,
        bearerExcludedUrls: ['/assets'],
      });
    return this.keycloak.init({
      config: keycloakConfig,
      initOptions: {
        onLoad: 'login-required', // or 'check-sso' if you want to check session silently
      },
      enableBearerInterceptor: true,
      bearerExcludedUrls: ['/assets'],
    });
  }
}
