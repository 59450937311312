import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, Subject, of } from 'rxjs';
import { catchError, map, shareReplay, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { from } from 'rxjs';

import { SharedVarService } from '../../../services/SharedVarService/shared-var-service.service';
import { NotificationsService } from '@app/shared/services/notifications.service';
import version from '../../../../../package.json';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '@app/services/user/user.service';
import { KeycloakService } from 'keycloak-angular';
import { environment } from '@src/environments/environment';


@Component({
  selector: 'app-menu-aside',
  templateUrl: './menu-aside.component.html',
  styleUrls: ['./menu-aside.component.css'],
})
export class MenuAsideComponent implements OnInit, OnDestroy {
  title: string = '';
  letters: string = '';
  user: string = '';
  userRole: number = -1;
  isDetails: boolean = false;
  subject = new Subject<any>();
  version = version.version;
  hideTabs: boolean = false;
  urlIpConnector: string = environment.urlIpConnector;

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private sharedVar: SharedVarService,
    private _cdr: ChangeDetectorRef,
    private userService: UserService,
    private keycloakService: KeycloakService,
    private router: Router,
    private notificationsAlerts: NotificationsService,
    public dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.sharedVar.getValue().subscribe((value) => {
      this.title = value;
      this.isDetails = !isNaN(parseInt(this.title.slice(-1))) ? true : false;
      this._cdr.detectChanges();
    });

    this.getUserInitials().subscribe((letters) => {
      this.letters = letters;
    });

  }

  getCurrentUserProfile(): Observable<any> {
    return from(this.keycloakService.loadUserProfile());
  }

  getUserInitials(): Observable<string> {
    return this.getCurrentUserProfile().pipe(
      map(profile => {
        console.log(profile)
        const firstName = profile.firstName || '';
        const lastName = profile.lastName || '';
        
        if (firstName && lastName) {
          return `${firstName[0]}${lastName[0]}`.toUpperCase();
        } else if (firstName) {
          return firstName[0].toUpperCase();
        } else if (profile.username) {
          return profile.username[0].toUpperCase();
        } else {
          return '';
        }
      })
    );
  }
  

  getUserAccountConsoleUrl(): string {
    const keycloakInstance = this.keycloakService.getKeycloakInstance();

    return keycloakInstance.createAccountUrl();
  }

  back(): void {
    this.isDetails ? window.history.back() : false;
  }

  logout(): void {
    this.router.navigate(['/login']);
  }

  ngOnDestroy(): void {
    this.subject.complete();
    this.subject.unsubscribe();
  }
}
