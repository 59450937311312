import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Location } from '@app/core/interfaces/location.interface';

@Component({
  selector: 'app-dialog-location-information',
  templateUrl: './dialog-location-information.component.html',
  styleUrls: ['./dialog-location-information.component.css'],
})
export class DialogLocationInfoComponent implements OnInit, OnDestroy {
  form!: FormGroup;
  subject = new Subject<any>();
  public edit = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Location,
    public dialogRef: MatDialogRef<DialogLocationInfoComponent>
  ) {
    console.log(this.data);
    this.form = new FormGroup({
      id: new FormControl(this.data.id),
      name: new FormControl(this.data.name),
      category: new FormControl(this.data.locationCategory.name),
      gps: new FormControl(
        this.data.gpsLat +
          ' ' +
          this.data.gpsLong +
          ' ' +
          this.data.gpsAlt
      ),
      status: new FormControl(this.data.locationStatus.name),
    });
  }

  ngOnInit(): void {}

  get formDialog(): any {
    return this.form.controls;
  }

  ngOnDestroy(): void {
    this.subject.complete();
    this.subject.unsubscribe();
  }
}
