<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #drawer
    class="sidenav"
    fixedInViewport
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false"
  >
    <mat-toolbar>
      <img
        src="/assets/icons/logo_v1.svg"
        width="120px"
        alt=""
        class="img-fluid d-block mt-5"
      />
    </mat-toolbar>
    <mat-nav-list class="mt-5">
      <a
        *ngIf="!hideTabs"
        mat-list-item
        routerLink="/locations"
        routerLinkActive="navigate-item-active"
        class="navigate-item"
      >
        <span
          [inlineSVG]="'./assets/icons/locations.svg'"
          class="icon-navigate"
        >
        </span>
        &nbsp; Locations
      </a>
      <a
        mat-list-item
        routerLink="/fleets"
        routerLinkActive="navigate-item-active"
        class="navigate-item"
      >
        <span
          [inlineSVG]="'./assets/icons/fleet.svg'"
          class="icon-navigate"
        >
        </span>
        &nbsp; Fleet
      </a>
      <a
        mat-list-item
        routerLink="/maintenances"
        routerLinkActive="navigate-item-active"
        class="navigate-item"
      >
        <span
          [inlineSVG]="'./assets/icons/maintenance.svg'"
          class="icon-navigate"
        >
        </span>
        &nbsp; Maintenances
      </a>
      <a
        *ngIf="!hideTabs"
        mat-list-item
        routerLink="/rides"
        routerLinkActive="navigate-item-active"
        class="navigate-item"
      >
        <span
          [inlineSVG]="'./assets/icons/rides.svg'"
          class="icon-navigate"
        >
        </span>
        &nbsp; Rides
      </a>

      <a
        mat-list-item
        href={{urlIpConnector}}
        target="_blank"
        class="navigate-item"
      >
        <span
          [inlineSVG]="'./assets/icons/external-link.svg'"
          class="icon-navigate"
        >
        </span>
        &nbsp; IP Connector
      </a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary" class="bg-toolbar col-12">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async"
      >
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span [ngClass]="{ 'cursor-pointer': isDetails }" (click)="back()">
        <mat-icon *ngIf="isDetails">
          <span class="material-icons vertical-align-sub">
            keyboard_arrow_left
          </span>
        </mat-icon>
        {{ title }}
      </span>
      <div class="d-flex justify-content-end align-middle ms-auto">
        <mat-chip-list aria-label="Fish selection">
          <mat-chip class="btn-green-dark user-nav" selected>{{
            letters
          }}</mat-chip>
        </mat-chip-list>
        <button mat-button [matMenuTriggerFor]="menu">
          {{ user }}
          <mat-icon>
            <span class="material-icons"> keyboard_arrow_down </span>
          </mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <a
          href={{getUserAccountConsoleUrl()}}
          target="_blank"
          >
          <button
            mat-menu-item
            style="width: 250px"
          >

          <mat-icon>
            <span class="material-icons icon-green"> account_box </span>
          </mat-icon>Manage Account</button>
        </a>
          <hr />

          <button mat-menu-item disabled class="text-center">
            version {{ version }}
          </button>
        </mat-menu>
      </div>
    </mat-toolbar>
    <!-- Add Content Here -->
    <router-outlet></router-outlet>
    <!-- <div class="content">
    </div> -->
  </mat-sidenav-content>
</mat-sidenav-container>
