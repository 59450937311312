// src/environments/keycloak.config.ts

import { KeycloakConfig } from 'keycloak-js';
const rootElement: HTMLElement | null = document.getElementById('root');
function attributeFunction(input: string): string | null {
  return rootElement?.getAttribute(`data-${input}`) ?? null;
}

const BASE = attributeFunction('api-base-domain') ?? 'uphi.cc';
const clientId = attributeFunction('auth-client-id') ?? 'uphi.cc';
const keycloakConfig: KeycloakConfig = {
  url: `https://auth.${BASE}`,
  realm: 'jedsy',
  clientId: clientId,
};

export default keycloakConfig;
